import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { client } from "src/context/siteContext";
import { RevealOnMount } from "src/components/reveal-on-mount";
import { SEO } from "src/components/SEO";
import { Collection, CollectionInterface } from "src/components/collection";
import { useLocation } from "@reach/router";

const fetcher = () => client.product.fetchAll(250);

export interface ShopProps {
  pageContext: {
    main: {
      title: string;
      slug: {
        current: string;
      };
      productLandingPages: [
        {
          content: {
            main: {
              categories: [
                {
                  slug: {
                    current: string;
                  };
                  collections: [CollectionInterface];
                }
              ];
            };
          };
        }
      ];
    };
    meta: {};
  };
  location: {
    pathname: string;
  };
}

const Shop = ({ pageContext, location }: ShopProps) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const { main, meta } = pageContext;
  const { productLandingPages, slug } = main;

  // fetch live product data
  const { data, error } = useSWR("productData", fetcher);

  const { hash } = location;

  useEffect(() => {
    if (!data) {
      return;
    }
  }, [data]);

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const destination = document.getElementById(hash.substring(1));
        if (destination) {
          const { offsetTop } = destination;

          window.scrollTo({
            top: offsetTop,
            behavior: "smooth",
          });
        }
      }, 50);
    }
  }, [hash]);

  return (
    <RevealOnMount location={location}>
      <SEO metaInfo={meta} pagePath={location.pathname} slug={slug.current} />
      {productLandingPages &&
        productLandingPages.length > 0 &&
        productLandingPages.map((landingPage) => {
          const categories = landingPage.content.main.categories;
          return (
            categories?.length > 0 &&
            categories.map((category) => {
              const collections = category.collections;
              return (
                <div id={category.slug && category.slug.current}>
                  {collections &&
                    collections.length > 0 &&
                    collections.map((collection: CollectionInterface) => {
                      return (
                        <Collection
                          isCoursePLP={
                            collection.slug.current === "mindfulness" ||
                            collection.slug.current === "mindfulness-courses"
                          }
                          collection={collection}
                          key={collection._id}
                          liveProducts={data}
                        />
                      );
                    })}
                </div>
              );
            })
          );
        })}
    </RevealOnMount>
  );
};

export default Shop;
