import React from "react";
import { graphql } from "gatsby";
import Shop from "src/templates/shop";

export const query = graphql`
  query ShopQuery {
    page: sanityShop(_id: { regex: "/(drafts.|)shop/" }) {
      content: _rawContent(resolveReferences: { maxDepth: 16 })
    }
  }
`;

const ShopPage = (props: any) => {
  const { location } = props;
  const content = props.data.page.content;
  return <Shop location={location} pageContext={content} />;
};

export default ShopPage;
